import { BaseRepository } from '@/api/baseRepository';
import { convertQueryToGetParams, convertQueryToJsonRpc } from '@/shared/api';
import enums from '@/config/enums';

const BACKEND_ENDPOINTS = {
  // Usage statistics
  USAGE_STATISTICS_WIDGETS: {
    url: '/v1/stats/widgets/query',
    filter: {
      date: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      client_id: { type: enums.API_FILTER_TYPES.IN_INT },
      client_source_id: { type: enums.API_FILTER_TYPES.IN_INT },
      client_source_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      client_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      client_package_expire_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      client_country_id: { type: enums.API_FILTER_TYPES.IN_INT },
      client_activated: { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
      client_support_name: { type: enums.API_FILTER_TYPES.Q_STRING },
      client_support_email: { type: enums.API_FILTER_TYPES.Q_STRING },
      user_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_license_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_position_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_source_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_source_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      user_status: { type: enums.API_FILTER_TYPES.IN_INT },
    },
  },
  USAGE_STATISTICS_HISTOGRAM: {
    url: '/v1/stats/histogram/query',
    filter: {
      date: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      client_id: { type: enums.API_FILTER_TYPES.IN_INT },
      client_source_id: { type: enums.API_FILTER_TYPES.IN_INT },
      client_source_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      client_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      client_package_expire_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      client_country_id: { type: enums.API_FILTER_TYPES.IN_INT },
      client_activated: { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
      client_support_name: { type: enums.API_FILTER_TYPES.Q_STRING },
      client_support_email: { type: enums.API_FILTER_TYPES.Q_STRING },
      user_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_license_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_position_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_source_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_source_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      user_status: { type: enums.API_FILTER_TYPES.IN_INT },
    },
  },
  USAGE_STATISTICS_CLIENTS: {
    url: '/v1/stats/clients/query',
    filter: {
      date: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      client_id: { type: enums.API_FILTER_TYPES.IN_INT },
      client_source_id: { type: enums.API_FILTER_TYPES.IN_INT },
      client_source_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      client_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      client_package_expire_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      client_country_id: { type: enums.API_FILTER_TYPES.IN_INT },
      client_activated: { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
      client_support_name: { type: enums.API_FILTER_TYPES.Q_STRING },
      client_support_email: { type: enums.API_FILTER_TYPES.Q_STRING },
    },
  },
  USAGE_STATISTICS_USERS: {
    url: '/v1/stats/users/query',
    filter: {
      date: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      client_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_license_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_position_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_source_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_source_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      user_status: { type: enums.API_FILTER_TYPES.IN_INT },
      client_support_name: { type: enums.API_FILTER_TYPES.Q_STRING },
      client_support_email: { type: enums.API_FILTER_TYPES.Q_STRING },
    },
  },

  // Usage statistics widgets
  USAGE_STATISTICS_WIDGET_SUMMARY: {
    url: '/v1/stats/dashboard_widgets/query',
    filter: {
      date: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      client_id: { type: enums.API_FILTER_TYPES.IN_INT },
      client_source_id: { type: enums.API_FILTER_TYPES.IN_INT },
      client_source_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      client_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      client_package_expire_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      client_country_id: { type: enums.API_FILTER_TYPES.IN_INT },
      client_activated: { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
      client_support_name: { type: enums.API_FILTER_TYPES.Q_STRING },
      client_support_email: { type: enums.API_FILTER_TYPES.Q_STRING },
      user_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_license_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_position_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_source_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_source_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      user_status: { type: enums.API_FILTER_TYPES.IN_INT },
    },
  },
  USAGE_STATISTICS_CLIENTS_WIDGETS: {
    url: '/v1/stats/client/widgets/query',
    filter: {
      date: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      client_id: { type: enums.API_FILTER_TYPES.IN_INT },
      client_source_id: { type: enums.API_FILTER_TYPES.IN_INT },
      client_source_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      client_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      client_package_expire_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE },
      client_country_id: { type: enums.API_FILTER_TYPES.IN_INT },
      client_activated: { type: enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
      client_support_name: { type: enums.API_FILTER_TYPES.Q_STRING },
      client_support_email: { type: enums.API_FILTER_TYPES.Q_STRING },
    },
  },
  USAGE_STATISTICS_USERS_WIDGETS: {
    url: '/v1/stats/user/widgets/query',
    filter: {
      date: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      client_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_license_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_position_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_source_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_source_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      user_created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      user_status: { type: enums.API_FILTER_TYPES.IN_INT },
      client_support_name: { type: enums.API_FILTER_TYPES.Q_STRING },
      client_support_email: { type: enums.API_FILTER_TYPES.Q_STRING },
    },
  },
};

export function getUSClients(data = null) {
  const endpoint = BACKEND_ENDPOINTS.USAGE_STATISTICS_CLIENTS;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

export function getUSUsers(data = null) {
  const endpoint = BACKEND_ENDPOINTS.USAGE_STATISTICS_USERS;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

export function getUSWidgets(data = null) {
  const endpoint = BACKEND_ENDPOINTS.USAGE_STATISTICS_WIDGETS;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

export function getUSHistogram(data = null) {
  const endpoint = BACKEND_ENDPOINTS.USAGE_STATISTICS_HISTOGRAM;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

// Usage statistics widgets
export function getUSWidgetSummary(data = null) {
  const endpoint = BACKEND_ENDPOINTS.USAGE_STATISTICS_WIDGET_SUMMARY;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

export function getUSWidgetClients(data = null) {
  const endpoint = BACKEND_ENDPOINTS.USAGE_STATISTICS_CLIENTS_WIDGETS;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

export function getUSWidgetUsers(data = null) {
  const endpoint = BACKEND_ENDPOINTS.USAGE_STATISTICS_USERS_WIDGETS;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

export default {
  getUSClients,
  getUSUsers,
  getUSWidgets,
  getUSHistogram,

  getUSWidgetSummary,
  getUSWidgetClients,
  getUSWidgetUsers,
};
