import { BaseRepository } from '@/api/baseRepository';
import { mergeQueryParams, replaceEnvPath } from '@/shared/utils';
import { convertQueryToJsonRpc } from '@/shared/api';
import {
  API_FILTER_TYPES,
  PRODUCTS,
} from "@/config/enums";

export const BACKEND_ENDPOINTS = {
  USERS: { url: '/v2/users' },
  USERS_QUERY: {
    url: '/v2/users/query',
    filter: {
      id: { type: API_FILTER_TYPES.IN_INT },
      client_id: { type: API_FILTER_TYPES.EQ_INT },
      license_id: { type: API_FILTER_TYPES.EQ_INT },
      source_id: { type: API_FILTER_TYPES.EQ_INT },
      source_type_id: { type: API_FILTER_TYPES.EQ_INT },
      status: { type: API_FILTER_TYPES.EQ_INT },
      invite_status: { type: API_FILTER_TYPES.EQ_INT },
      created_at: { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      modified_at: { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      invited_at: { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      country_id: { type: API_FILTER_TYPES.IN_INT },
    },
    queryFilter: {
      suspended: { type: API_FILTER_TYPES.EQ_BOOLEAN_NUMBER },
    },
  },
  USER: { url: '/v2/users/{user_id}' },
  USER_INIT: { url: '/v2/users/{user_id}/init' },
  USER_INVITE: { url: '/v2/client_users/{client_id}/{user_id}/invite' },
  USER_TOKEN: { url: '/v1/users/{user_id}/token/{token_type}' },
  USERS_AGG_QUERY: {
    url: '/v2/users/agg/query',
    filter: {
      id: { type: API_FILTER_TYPES.IN_INT },
      source_type_id: { type: API_FILTER_TYPES.EQ_INT },
      source_id: { type: API_FILTER_TYPES.EQ_STRING },
      email: { type: API_FILTER_TYPES.EQ_STRING },
      created_at: { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      modified_at: { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      is_suspended: { type: API_FILTER_TYPES.EQ_BOOLEAN },
      is_dummy: { type: API_FILTER_TYPES.EQ_BOOLEAN },
      // Client object fields
      'client.id': { type: API_FILTER_TYPES.IN_INT },
      'client.name': { type: API_FILTER_TYPES.Q_STRING },
      'client.is_suspended': { type: API_FILTER_TYPES.EQ_BOOLEAN },
      'client.created_at': { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      'client.modified_at': { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      'client.source_type_id': { type: API_FILTER_TYPES.IN_INT },
      'client.source_id': { type: API_FILTER_TYPES.IN_STRING },
      // ClientUser object fields
      'client_user.smart_settings.license_id': { type: API_FILTER_TYPES.EQ_INT },
      'client_user.smart_settings.status': { type: API_FILTER_TYPES.EQ_INT },
      'client_user.smart_settings.invite_status': { type: API_FILTER_TYPES.EQ_INT },
      'client_user.smart_settings.invited_at': { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      // Subscription object fields
      'subscription.product_id': { type: API_FILTER_TYPES.IN_INT },
      'subscription.order_id': { type: API_FILTER_TYPES.Q_STRING },
      'subscription.start_date': { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      'subscription.end_date': { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      'subscription.is_suspended': { type: API_FILTER_TYPES.EQ_BOOLEAN },
      'subscription.created_at': { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      'subscription.modified_at': { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      'subscription.smart_settings.subscription_type_id': { type: API_FILTER_TYPES.IN_INT },
      'subscription.smart_settings.project_history_start_date': { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      'subscription.smart_settings.support_phone': { type: API_FILTER_TYPES.Q_STRING },
      'subscription.smart_settings.support_email': { type: API_FILTER_TYPES.Q_STRING },
      'subscription.smart_settings.support_name': { type: API_FILTER_TYPES.Q_STRING },
      'subscription.smart_settings.modules': { type: API_FILTER_TYPES.IN_INT },
      'subscription.smart_settings.fixed_modules': { type: API_FILTER_TYPES.IN_INT },
      'subscription.analys_settings.modules': { type: API_FILTER_TYPES.IN_INT },
      'subscription.analys_settings.fixed_modules': { type: API_FILTER_TYPES.IN_INT },
      // UserProfile object fields
      'user_profile.is_suspended': { type: API_FILTER_TYPES.EQ_BOOLEAN },
      'user_profile.created_at': { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      'user_profile.modified_at': { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      'user_profile.smart_settings.modules': { type: API_FILTER_TYPES.IN_INT },
      'user_profile.smart_settings.fixed_modules': { type: API_FILTER_TYPES.IN_INT },
      'user_profile.analys_settings.modules': { type: API_FILTER_TYPES.IN_INT },
      'user_profile.analys_settings.fixed_modules': { type: API_FILTER_TYPES.IN_INT },
      'client.smart_settings.country_id': { type: API_FILTER_TYPES.IN_INT },
    },
  },
  USER_PROFILES_QUERY: {
    url: '/v2/user_profiles/query',
    filter: {
      is_suspended: { type: API_FILTER_TYPES.EQ_BOOLEAN },
      created_at: { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      modified_at: { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      subscription_id: { type: API_FILTER_TYPES.IN_INT },
      'user.id': { type: API_FILTER_TYPES.IN_INT },
      'user.email': { type: API_FILTER_TYPES.Q_STRING },
      'user.first_name': { type: API_FILTER_TYPES.Q_STRING },
      'user.last_name': { type: API_FILTER_TYPES.Q_STRING },
      'user.is_suspended': { type: API_FILTER_TYPES.EQ_BOOLEAN },
      'user.is_dummy': { type: API_FILTER_TYPES.EQ_BOOLEAN },
      'smart_settings.modules': { type: API_FILTER_TYPES.IN_INT },
      'smart_settings.fixed_modules': { type: API_FILTER_TYPES.IN_INT },
      'analys_settings.modules': { type: API_FILTER_TYPES.IN_INT },
      'analys_settings.fixed_modules': { type: API_FILTER_TYPES.IN_INT },
    },
  },
  USER_PROFILES: { url: '/v2/user_profiles' },
  USER_PROFILE: { url: '/v2/user_profiles/{user_profile_id}' },
  MY_CUSTOM_NOTIFICATIONS_SETUP: { url: '/v1/me/custom_notification_setup' },
};

// Get users
export function getUsers(data = null) {
  const endpoint = BACKEND_ENDPOINTS.USERS_QUERY;
  const query = convertQueryToJsonRpc(endpoint, data);
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

export function getUsersAggList(data = null) {
  const endpoint = BACKEND_ENDPOINTS.USERS_AGG_QUERY;
  const query = convertQueryToJsonRpc(
    endpoint,
    mergeQueryParams(
      {
        filter: {
          'subscription.product_id': {
            in: [PRODUCTS.SMART],
          },
        },
      },
      data,
    )
  );
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

// Init user
export function postUser(data = {}) {
  const path = BACKEND_ENDPOINTS.USERS.url;
  return BaseRepository.post(path, data);
}

// Init user
export function initUser(user_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.USER_INIT.url, { user_id });
  return BaseRepository.put(path, data, {
    useIdmp: false,
    checkErrors: false,
  });
}

// Send invite to user
export function inviteUser(client_id, user_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.USER_INVITE.url, { client_id, user_id });
  return BaseRepository.put(path, null, { useIdmp: false });
}

// Returns user data
export function getUser(user_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.USER.url, { user_id });
  return BaseRepository.get(path, null, { useIdmp: false });
}

// Update user data
export function patchUser(user_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.USER.url, { user_id });
  return BaseRepository.patch(path, data);
}

// Delete user
export function deleteUser(user_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.USER.url, { user_id });
  return BaseRepository.delete(path);
}

// Returns user token by token type
export function getToken(user_id, token_type, query = null) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.USER_TOKEN.url, { user_id, token_type });
  return BaseRepository.get(path, {
    params: query,
    useIdmp: false,
  });
}

export function getUserProfiles(data = null) {
  const endpoint = BACKEND_ENDPOINTS.USER_PROFILES_QUERY;

  const query = convertQueryToJsonRpc(
    endpoint,
    mergeQueryParams(
      {
        filter: {
          'subscription.product_id': {
            in: [PRODUCTS.SMART],
          },
        },
        smart_selections: 0,
      },
      data,
    )
  );

  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

export function getUserProfile(user_profile_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.USER_PROFILE.url, { user_profile_id });
  return BaseRepository.get(path, null, { useIdmp: false });
}

export function postUserProfile(data = {}) {
  const path = BACKEND_ENDPOINTS.USER_PROFILES.url;
  return BaseRepository.post(path, data);
}

export function patchUserProfile(user_profile_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.USER_PROFILE.url, { user_profile_id });
  return BaseRepository.patch(path, data);
}

export function deleteUserProfile(user_profile_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.USER_PROFILE.url, { user_profile_id });
  return BaseRepository.delete(path);
}

export function getMyCustomNotificationSetup() {
  const path = BACKEND_ENDPOINTS.MY_CUSTOM_NOTIFICATIONS_SETUP.url;
  return BaseRepository.get(path, null, { useIdmp: false });
}

export function postMyCustomNotificationSetup(data = {}) {
  const path = BACKEND_ENDPOINTS.MY_CUSTOM_NOTIFICATIONS_SETUP.url;
  return BaseRepository.post(path, data);
}
